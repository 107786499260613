@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@layer base {
  @font-face {
    font-family: Recoleta Alt;
    font-weight: 200;
    src: url(assets/fonts/recoleta/RecoletaAlt-Thin.ttf);
  }
  @font-face {
    font-family: Recoleta Alt;
    font-weight: 300;
    src: url(assets/fonts/recoleta/RecoletaAlt-Light.ttf);
  }
  @font-face {
    font-family: Recoleta Alt;
    font-weight: 400;
    src: url(assets/fonts/recoleta/RecoletaAlt-Regular.ttf);
  }
  @font-face {
    font-family: Recoleta Alt;
    font-weight: 500;
    src: url(assets/fonts/recoleta/RecoletaAlt-Medium.ttf);
  }
  @font-face {
    font-family: Recoleta Alt;
    font-weight: 600;
    src: url(assets/fonts/recoleta/RecoletaAlt-SemiBold.ttf);
  }
  @font-face {
    font-family: Recoleta Alt;
    font-weight: 700;
    src: url(assets/fonts/recoleta/RecoletaAlt-Bold.ttf);
  }
  @font-face {
    font-family: Recoleta Alt;
    font-weight: 900;
    src: url(assets/fonts/recoleta/RecoletaAlt-Black.ttf);
  }

  @font-face {
    font-family: Sofia Pro;
    font-weight: 200;
    src: url(assets/fonts/sofia/Sofia Pro Ultra Light.ttf);
  }
  @font-face {
    font-family: Sofia Pro;
    font-weight: 300;
    font-style: italic;
    src: url(assets/fonts/sofia/Sofia Pro Light italic.ttf);
  }
  @font-face {
    font-family: Sofia Pro;
    font-weight: 300;
    src: url(assets/fonts/sofia/Sofia Pro Light.ttf);
  }
  @font-face {
    font-family: Sofia Pro;
    font-weight: 500;
    src: url(assets/fonts/sofia/Sofia Pro Medium.ttf);
  }
  @font-face {
    font-family: Sofia Pro;
    font-weight: 400;
    src: url(assets/fonts/sofia/Sofia Pro Regular.ttf);
  }
  @font-face {
    font-family: Sofia Pro;
    font-weight: 700;
    src: url(assets/fonts/sofia/Sofia Pro Bold.ttf);
  }
  @font-face {
    font-family: Sofia Pro;
    font-weight: 600;
    src: url(assets/fonts/sofia/Sofia Pro Semi Bold.ttf);
  }
}
